import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Button from '@material-ui/core/Button';
import { Loading } from 'react-admin';

class DeviceValidationButton extends Component {
  state = {
    value: 0,
    loading: false,
    validationResult: {
      valid: [],
      unknown: [],
      invalid: []
    }
  };
  handleClick = () => {
    const { record } = this.props;

    this.setState({
      loading: true
    });
    fetch(
      process.env.REACT_APP_API_BASE_PATH +
        `/application-devices/${record.id}/fcm/validate`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + localStorage.getItem('token')
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ validationResult: data, loading: false });
      })
      .catch(e => {
        console.error(e);
        this.setState({ loading: false });
      });
  };

  render() {
    const { validationResult, loading } = this.state;

    return (
      <div
        style={{
          marginTop: '2em'
        }}
      >
        {!loading && (
          <Button
            color="primary"
            variant="contained"
            label="Device Validation"
            onClick={this.handleClick}
          >
            Validate Devices
          </Button>
        )}
        {loading && <Loading />}
        <p>
          A maximum of 500 devices can be validated with one request. If the
          current application have 5000 devices, 10 requests will be fired in
          the background an you will receive a combined result.
          <b>Only active devices will be validated!</b>
        </p>
        {
          <div>
            <pre>{JSON.stringify(validationResult, null, 2)}</pre>
          </div>
        }
      </div>
    );
  }
}

export default connect(null, {
  push
})(DeviceValidationButton);
